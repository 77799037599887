import React from "react";
import { Link } from "gatsby";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import MigrationIcon from "../../images/migration.svg";
import AWS from "../../images/assets/aws.svg";
import Study from "../../images/assets/study.svg";
import Migra from "../../images/assets/migra.svg";
import Sow from "../../images/assets/sow.svg";
import Assessment from "../../images/Infrastructure_Assessment_Report.svg";
import Migration from "../../images/Detailed_Migration_Roadmap.svg";
import Project from "../../images/A_comprehensive_project_plan.svg";
import Recommendations from "../../images/Specialized_recommendations.svg";
import Target from "../../images/Target_setup_costs_&_funding_assistance.svg";
import Capex from "../../images/CAPex_&_OPex_Reduction_Assistance.svg";
import Training from "../../images/Training_your_teams.svg";
import Automation from "../../images/Automation_opportunities.svg";

const MigrationCampaign = ({ section, btntext, service }) => {
  return (
    <div className="tab_sec">
      <Tabs>
        <TabList>
          <Tab>
            <span className="campaign_tab_title">{section.tabs[0]}</span>
          </Tab>
          <Tab>
            <span className="campaign_tab_title">{section.tabs[1]}</span>
          </Tab>
          {/* <Tab><span>{section.tabs[2]}</span></Tab> */}
        </TabList>

        <TabPanel
          className={`react-tabs__tab-panel ${service ? "servicePanel" : ""}`}
        >
          <div className="row">
            <div className="col-md-5 col-12">
              <div className="inside_tab_Data">
                <div className="list">
                  {section.tab1.opt1}
                  <span>{section.tab1.opt1description}</span>
                </div>
                <div className="list">
                  {section.tab1.opt2}
                  <span>{section.tab1.opt2description}</span>
                </div>
                <div className="list">
                  {section.tab1.opt3}
                  <span>{section.tab1.opt3description}</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <figure className="figure float-right">
                <img src={MigrationIcon} alt="360" loading="lazy" />
              </figure>
              <div className="CampaignsHeading">{section.info}</div>
              <div className="opsBtnwrap d-flex justify-content-center pt-4">
                <Link className="opsBtnlink" to={`/contact-us/`}>
                  <p>{btntext}</p>
                </Link>
              </div>
            </div>
            <div className="tab_name">{section.tab1.pillars.name}</div>
          </div>
          <div className="tab_links">
            <ul className="listing">
              <li>{section.tab1.pillars.options[0]}</li>
              <li>{section.tab1.pillars.options[1]}</li>
              <li>{section.tab1.pillars.options[2]}</li>
              <li>{section.tab1.pillars.options[3]}</li>
            </ul>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="outcome">
            <div className="row">
              <div className="col-md-6">
                <div className="outcome_inside col-md-12">
                  <div className="solImgwrap col-md-1">
                    <img src={Assessment} alt={section.tab2.opt1} width="100%" height="100%" />
                  </div>
                  <div
                    className="solName col-md-11"
                    dangerouslySetInnerHTML={{ __html: section.tab2.opt1 }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="outcome_inside col-md-12">
                  <div className="solImgwrap col-md-1">
                    <img src={Migration} alt={section.tab2.opt2} width="100%" height="100%"/>
                  </div>
                  <div
                    className="solName col-md-11"
                    dangerouslySetInnerHTML={{ __html: section.tab2.opt2 }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="outcome_inside col-md-12">
                  <div className="solImgwrap col-md-1">
                    <img src={Project} alt={section.tab2.opt3} width="100%" height="100%"/>
                  </div>
                  <div
                    className="solName col-md-11"
                    dangerouslySetInnerHTML={{ __html: section.tab2.opt3 }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="outcome_inside col-md-12">
                  <div className="solImgwrap col-md-1">
                    <img src={Recommendations} alt={section.tab2.opt4} width="100%" height="100%" />
                  </div>
                  <div
                    className="solName col-md-11"
                    dangerouslySetInnerHTML={{ __html: section.tab2.opt4 }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="outcome_inside col-md-12">
                  <div className="solImgwrap col-md-1">
                    <img src={Target} alt={section.tab2.opt5} width="100%" height="100%"/>
                  </div>
                  <div
                    className="solName col-md-11"
                    dangerouslySetInnerHTML={{ __html: section.tab2.opt5 }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="outcome_inside col-md-12">
                  <div className="solImgwrap col-md-1">
                    <img src={Capex} alt={section.tab2.opt6} width="100%" height="100%"/>
                  </div>
                  <div
                    className="solName col-md-11"
                    dangerouslySetInnerHTML={{ __html: section.tab2.opt6 }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="outcome_inside col-md-12">
                  <div className="solImgwrap col-md-1 ">
                    <img src={Training} alt={section.tab2.opt7} width="100%" height="100%"/>
                  </div>
                  <div
                    className="solName col-md-11 col-md-11"
                    dangerouslySetInnerHTML={{ __html: section.tab2.opt7 }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="outcome_inside col-md-12">
                  <div className="solImgwrap col-md-1">
                    <img src={Automation} alt={section.tab2.opt8} width="100%" height="100%"/>
                  </div>
                  <div
                    className="solName col-md-11"
                    dangerouslySetInnerHTML={{ __html: section.tab2.opt8 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default MigrationCampaign;
