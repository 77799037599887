import React from "react";
import { Link } from 'gatsby';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CampaignIcon from '../../images/360.svg'
import tabicon from '../../images/tab-icon.svg'
import Concrete from '../../images/Concrete.svg'
import Report from '../../images/assets/report.svg'
import Cutting from '../../images/assets/cutting.svg'

const OptCampaign = ({ section, btntext, service }) => {
  return (
    <div className="tab_sec">
      <Tabs>
        <TabList >
          <Tab><span className="campaign_tab_title">{section.tabs[0]}</span></Tab>
          <Tab><span className="campaign_tab_title">{section.tabs[1]}</span></Tab>
        </TabList>

        <TabPanel className={`react-tabs__tab-panel ${service ? 'servicePanel' : ''}`}>
          <div className="row">
            <div className="col-md-5 col-12">
              <div className="inside_tab_Data">
                <div className="list">
                  {section.tab1.opt1}
                  <span>{section.tab1.opt1description}</span>
                </div>
                <div className="list">
                  {section.tab1.opt2}
                  <span>{section.tab1.opt2description}</span>
                </div>
                <div className="list">
                  {section.tab1.opt3}
                  <span>{section.tab1.opt3description}</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <figure className="figure float-right">
                <img src={CampaignIcon} alt="360" loading="lazy" />
              </figure>
              <div className="CampaignsHeading">
                {section.info}
              </div>
              <div className="opsBtnwrap d-flex justify-content-center pt-4">
                <Link className="opsBtnlink" to={`/contact-us/`}>
                  <p>{btntext}</p>
                </Link>
              </div>
            </div>
            <div className="tab_name">
              {section.tab1.pillars.name}
            </div>
          </div>
          <div className="tab_links">
            <ul className="listing">
              <li>
                {section.tab1.pillars.options[0]}
              </li>
              <li>
                {section.tab1.pillars.options[1]}
              </li>
              <li>
                {section.tab1.pillars.options[2]}
              </li>
              <li>
                {section.tab1.pillars.options[3]}
              </li>
            </ul>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="outcome">
            <div className="row">
              <div className="col-md-6">
                <div className="outcome_inside">
                  <div className="solImgwrap">
                    <img src={tabicon} alt="tab" className="solutionImg" loading="lazy"/>
                  </div>
                  <span className="solName">{section.tab2.opt1}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="outcome_inside">
                  <div className="solImgwrap">
                    <Report />
                  </div>
                  <span className="solName">{section.tab2.opt2}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="outcome_inside">
                  <div className="solImgwrap">
                    <img src={Concrete} alt="tab" className="solutionImg" loading="lazy"/>
                  </div>
                  <span className="solName">{section.tab2.opt3}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="outcome_inside">
                  <div className="solImgwrap">
                    <Cutting />
                  </div>
                  <span className="solName">{section.tab2.opt4}</span>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default OptCampaign;