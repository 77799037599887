import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import consultPartner from "../../images/aws-partner-tier-badge.svg";
import { useStaticQuery, graphql } from "gatsby";
function Partner({ title, locale }) {
  const partnerdata = useStaticQuery(graphql`
    query {
      allStrapiPartners(sort: { order: ASC, fields: title }) {
        edges {
          node {
            locale
            partnerId
            heading
            description
            title
            why {
              title
              bulletpoints {
                des
                name
              }
            }
            logo {
              url
            }
          }
        }
      }
    }
  `).allStrapiPartners.edges;

  const filterData = partnerdata.filter((node) => node.node.locale === locale);

  return (
    <div className="partnerSec serviceHotspot" id="partners">
      <div className="partSecwrap">
        <div className="partsecleft partseccover">
          <h4 className="smallHeading">{title}</h4>
          <Slider className="partner-slider">
            {filterData
              .filter((val) => val.node.title !== "Vmware")
              .map((val, index) => (
                <div key={index}>
                  <div className="leftSection">
                    <h2 className="secHeading">{val.node.heading}</h2>
                    <p>{val.node.description}</p>
                  </div>
                  <div

                    className={`rightSection ${val.node.title === "Microsoft Azure"
                        ? "platformLogoLg"
                        : ""
                      }`}
                  >
                    {!["AWS", "AWS Public Support Statement"].includes(
                      val.node.title
                    ) && (
                        <img
                          src={val.node.logo.url}
                          alt={val.node.title}
                          style={{ width: "100%", height: "100%" }}
                        />
                      )}

                    {["AWS", "AWS Public Support Statement"].includes(
                      val.node.title
                    ) && (
                        <div className="aws_par_wrap">
                          <div className="consPartner">
                            <img src={consultPartner} alt="consultPartner" loading="lazy"/>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Partner;
