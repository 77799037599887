import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import {Link} from "gatsby";
const url = process.env.GATSBY_API_URL;

const EnquireNow = ({ data, locale }) => {
    // const [email, setEmail] = useState("")
    // const [errorMsg, setErrorMsg] = useState();
    // const [success, setSuccess] = useState(false);
    // useEffect(() => email.length == 0 && setErrorMsg(''), [email.length])
    // const handleForm = (e) => {
    //     e.preventDefault();
    //     const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    //     if (email.match(mailformat)) {
    //         axios.post(`${url}/enquire-nows`, { email: email })
    //             .then(() => {
    //                 setSuccess(true)
    //                 // axios.post(`${url}/email-send`, { to: email })
    //                 // .then(() => setSuccess(true))
    //                 // .catch(() => setSuccess(false))
    //             })
    //             .catch(() => setSuccess(false));
    //         setEmail("")
    //         setErrorMsg("")
    //     }
    //     else {
    //         setErrorMsg('* Please Enter the Valid Email');
    //     }

    // }
    // useEffect(() => {
    //     setTimer(3000);
    // }, [success]);
    // const setTimer = (delay) => {
    //     setTimeout(() => setSuccess(false), delay);

    // };

    return (
        <div className="startingCloud" id="enquire" >
        <div className="startingCloud-icon" style={{
            backgroundImage: `url(${require("../images/footer-icon.svg").default})`,
        }}>  
            </div>
            <div className="partner_container">
                <div className="row">
                    <div className="startingCloudHeading">{data.title}</div>
                    <div className="startingCloudEnquireNow">
                        <div className="opsBtnwrap">
                            {/* <form> */}

                                {/* <input type="text" name="reply_to" className="startingCloudInput" placeholder={data.email} value={email} onChange={(e) => setEmail(e.target.value)} /> */}
                                <Link to={`/contact-us/`} className="opsBtnlink"><p>{data.btn}</p></Link>
                                {/* {!success && <div style={{ color: "red", fontSize:"15px" }}>{errorMsg}</div>}
                                {success && <div style={{ color: "#0ED39B", fontSize:"15px" }}>Thank you, our team will be in touch with you shortly.</div>} */}
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}


export default EnquireNow;